// Palette generator: http://mcg.mbitson.com/
/* For use in src/lib/core/theming/_palette.scss */

$md-bcrmbranded: (
  50 : #ffebe4,
  100 : #ffcdbd,
  200 : #ffab91,
  300 : #ff8964,
  400 : #ff7043,
  500 : #ff5722,
  600 : #ff4f1e,
  700 : #ff4619,
  800 : #ff3c14,
  900 : #ff2c0c,
  A100 : #656565,
  A200 : #4A4A4A,
  A400 : #434343,
  A700 : #3a3a3a,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

@use '@angular/material' as mat;
$my-custom-typography-config: mat.define-typography-config();  // Default angular material

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
